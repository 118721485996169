/* login and signup divider correct it  --> make it individual in both login and signup */
/* footer bottom border is not align */

@media only screen and (min-width : 1200px) and (max-width: 1300px){
    .videobox2 ,.videobox4{
      bottom: 24%;
    }
    .choosebg {
        top: -16rem;
      }
      .banner .heading{
        white-space: nowrap;
      }

      .credintial{
        width: 26%;
      }


}

@media only screen and (min-width : 1300px) and (max-width: 1400px){
      .videobox2 ,.videobox4{
        bottom: 19%;
      }
      .choosebg {
        top: -12rem;
      }
      .yellowbox span{
        line-height: 2.5rem;
      }

      .banner .heading{
        white-space: nowrap;
      }

      .credintial{
        width: 25%;
      }
      
}

@media only screen and (min-width : 1400px) and (max-width: 1450px){
    .videobox2 ,.videobox4{
      bottom: 24.5%;
    }
    .banner .heading{
        white-space: nowrap;
      }

      /* .credintial{
        width: 23%;
      } */
}
@media only screen and (min-width : 1450px) and (max-width: 1600px){
    .videobox2 ,.videobox4{
      bottom: 13.5%;
    }

}

@media only screen and (min-width : 1600px) and (max-width: 1800px){
    .videobox2 ,.videobox4{
      bottom: 20%;
    }
}




@media only screen and (min-width : 305px) and (max-width: 1200px){
        .learningText .learnheading2{
            white-space: nowrap;
        }

        .realbannerbox{
            white-space: nowrap
        }
    body{
        zoom : 65.5%
    }
    .navbar-nav{
        margin-left: 0px !important;
        flex-direction: inherit;
        left: -14px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbaar-nav {
        position: relative;
        left: 6px;
    }

    .btn-groups button{
        font-size : 16px;
        
    }

    .call-btn{
        white-space: nowrap;
        position: relative;
        bottom: 110px;
        left : 60%;
    }

    .searchText{
        font-size: 1.2rem;
        margin-right: -2rem;
    }

    .search-icon{
        position: relative;
         left: -65px; 
         top : -4px;
    }


    .resFooter{
        /* margin-top: 1rem */
        padding: 1rem;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        z-index: 99999;
    }

    .input-group{
        width:100% !important;
        position: relative;
        right : 16%;
    }

    .credintial{
        width:fit-content
    }
    .loginBorder{
        left: 120px !important;
    height: 35px !important;
    top: 8px !important;
    }

    .angleDown {
        margin-left: 2rem;

        /* width: 20px; */
    }

    .signupbtn {
        padding-left: 0.5rem;
    }
    .signupBorder {
        border: 1px solid #dddddd91;
        position: absolute;
        /* top: 1px; */
        right: 54px;
        height: 34px;
        top: 9px;
        box-shadow: "rgb(215, 57, 41) 1px 0px 0px 0px";
        /* border: 1px solid rgb(255 255 255 / 0%); */
        /* border: 3px solid black !important; */
    }

    .toplayer {
        position: absolute;
        top: 6rem;
        width: 24%;
    }
    #root {
        width: fit-content;
        width: 150vw;
        overflow: hidden;
    }
    .heading {
        font-size: -1rem !important;
    }
    .subheading {
        font-size: 3rem;
        font-family: Georgia, 'Times New Roman', Times, serif;
        position: relative;
        top: 5rem;
        left: 1.5rem;
    }
    .subheadingtag {
        font-size: 55px;
    }
    .realbannerbox {
        font-size: 150%;
        position: relative;
        left: 0.5rem;
        top: 5rem;
        margin: 0px 1rem;
    }
    .grass {
        /* ✅ */
        position: absolute; 
        bottom: 0rem;
        width: 150%;
        right: -13rem;
    }
    .boyimage {
        z-index: 8888;
        position: relative;
        top: 90px;
        left: 11px;
        display: none;
    }
    .carouselMobile{
        left : 0px
    }
    .starimage {
        display: none   ;
    }

    .banner {
    height: 100%;
    background-size: cover;
    }
    .rightBanner{
        display : none;
    }
    .bg-layer {
        position: absolute;
        bottom: 30%;
        display: none;
        height: -5%;
    }
    .choosebg {
        position: absolute;
        top: -21% !important;
    }
    .videoboxSection{
        display:none !important;
    }
    .choosebg img {
        height: 60rem;
    }
    .educationyear {
        position: absolute;
        right: 2rem;
    }
    .studentImage{
        padding-top: 0rem;;
    }
    .yellowbox{
        padding-top: 3rem;
    }
    .transcircle1 {
        top: 290px;
    }
    .transcircle2 {
        /* top: 240px; */
        top : 15%;
    }
    .transcircle4 {
        /* top: 240px; */
        top : 15%;
        right : -4%;
    }
    .transcircle3 {
        right: 25rem;
        top: 15%;
    }
    .transcircle5 {
        /* bottom: 3rem; */
        left: -2rem;
    }
    .transcircle6 {
        position: absolute;
        bottom: 11rem;
    }
    .transcircle7 {
        /* bottom: 3rem; */
        right: 31%;
    }
    .transcircle8 {
        bottom: 11rem;
    }
    .transcircle9 {
        /* bottom: 3rem; */
        right: -1rem;
        border: 15px solid #f7a29a;
    }
    .learningText{
        position: relative;
        top : -50px;
    }
    .learnheading {
        font-size: 40px;
        line-height: 1;
    }
    .learnheading2 {
        font-size: 60px;
        line-height: 1;
    }
    .learnpara {
        font-size: 25px;
    }
    .videobox1 {
        top: 15.5rem;
        left: 6.7%;
    }
    .videobox2 {
        bottom: 18.5rem;
        left: 24.5%;
    }
    .videobox3 {
        top: 15.5rem;
        left: 42.5%;
    }
    .videobox4 {
        bottom: 18.5rem;
        right: 23%;
    }
    .videobox5 {
        top: 15.5rem;
        right: 5.3%;
    }

    .curve{
        height: 50vh !important;
    }
    .titlebox4{
            left: 65px;
        
    }   
    .headingnumber2, .headingnumber4 {
        position: absolute;
        right: 25%;
    }
    .brandname {
        font-size: 4rem !important;
    }
    .chooseheading {
        font-size: 2.2em;
    }
    .overlay{
        top : 100px !important
    }
    .overlay, .curve{
        display: none;
    }
    /* .carousel-indicators button:nth-child(1) img {
        height: 100px;
        position: absolute;
        left: 13.5%;
        bottom: 1%;
    } */

    /* .carousel-indicators button:nth-child(1) img {
        height: 100px;
        position: absolute;
        left: 6px;
        bottom: 1px;
      } */

      
.carousel-indicators button:nth-child(1) img {
    height: 100px;
    position: absolute;
    left: 2px;
    bottom: 7px;
    display: none;
  }
  
  .carousel-indicators button:nth-child(2) img {
    height: 100px;
    position: absolute;
    right: 200px;
    top: -28px;
  }
  
  .carousel-indicators button:nth-child(3) img {
    height: 100px;
    position: absolute;
    right: 105px;
    top: -30px;
  }
  
  .carousel-indicators button:nth-child(4) img {
    height: 100px;
    position: absolute;
    right: 21px;
    top: -55px;
  }
  .carousel-indicators button:nth-child(5) img {
    height: 100px;
    position: absolute;
    right: 282px;
    top: -55px;
}
      #carouselExampleIndicators{
        left : 0px !important;
      }

      .educationheading2{
        font-size: 3.5rem;
      }

      .button_shadow::after {
        left: -8px;
        bottom: -1px;
    }
    .btn-groups button {
        font-size: 71%;
    }
    .navbar-nav .border-right {
        top: 0rem;
    }
}


@media only screen and (min-width : 445px) and (max-width: 1200px){
    .realbannerbox {
        top: 2rem;
    }

    .subheading {
        top: 1.8rem;
    }

    .choosebg {
        top: -18% !important;
    }
}


@media only screen and (min-width : 1800px) and (max-width: 2100px){
    .videobox2 ,.videobox4{
      bottom: 21%;
    }
    .videobox1,.videobox3,.videobox5{
        top: 19rem;
    }

    /* .choosebg img {
        height: 1000px;
        width: 150% !important;
    }
    .resTop20rem{
        margin-top: 20rem !important;

    } */
}

@media only screen and (min-width : 2400px) and (max-width: 2600px){
    .videobox2 ,.videobox4{
      bottom: 21.5%;
    }
    .videobox1,.videobox3,.videobox5{
        top: 19rem;
    }
}


/* @media only screen and (min-width : 593px) and (max-width: 900px){
    .navbar-nav{
        margin-left: 0px !important;
        flex-direction: inherit;
        left: -14px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbaar-nav {
        position: relative;
        left: 6px;
    }

    .btn-groups button{
        font-size : 16px;
        
    }

    .call-btn{
        position: relative;
        bottom: 110px;
        left : 62%;
    }

    .input-group{
        width:100% !important;
        position: relative;
        right : 16%;
    }

    .search-icon{
    left: 74%;
    }

    .credintial{
        width:fit-content
    }
    .loginBorder{
        left: 114px !important;
    height: 35px !important;
    top: 8px !important;
    }
    .signupBorder {
        border: 1px solid #dddddd91;
        position: absolute;
        right: 45px;
        height: 34px;
        top: 7px;
        box-shadow: "rgb(215, 57, 41) 1px 0px 0px 0px";
    }

    .toplayer {
        position: absolute;
        top: 88px;
        width: 209px;
    }
    #root {
        width: fit-content;
        width: 150vw;
        overflow: hidden;
    }
    .heading{
        left: 25px;
    top: 163px;
    font-size: 60px;
    }
    .subheading {
        font-size: 50px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        position: relative;
        top: 70px;
        left: 13px;
    }
    .subheadingtag {
        font-size: 55px;
    }
    .realbannerbox {
        font-size: 25px;
        position: relative;
        left: 16px;
        top: 70px;
    }
    .grass {
        position: absolute;
        bottom: 13px;
        width: 150%;

    }
    .boyimage {
        z-index: 8888;
        position: relative;
        top: 90px;
        left: 11px;
        display: none;
    }
    .starimage {
        position: absolute;
        z-index: 8888;
        left: 56%;
        top: 55%;
        display: none   ;
    }
    .starimage img {
        width : 180px !important;
    }
    .banner {
    height: 100vh;
    background-size: cover;
    }
    .bg-layer {
        position: absolute;
        bottom: 30%;
        height: -5%;
    }
    .choosebg {
        position: absolute;
        top: -20% !important;
    }
    .educationyear {
        position: absolute;
        right: 100px;
        
    }
    .studentImage{
        padding-top: 15px;;
    }
    .yellowbox{
        padding-top: 40px;
    }
    .transcircle1 {
        top: 290px;
    }
    .transcircle2 {
        top: 240px;
    }
    .transcircle4 {
        top: 240px;
    }
    .transcircle3 {
        right: 20rem;
        top: 12%;
    }
    .transcircle5 {
        bottom: 9rem;
    }
    .transcircle6 {
        position: absolute;
        bottom: 11rem;
    }
    .transcircle7 {
        bottom: 9rem;
        right: 29%;
    }
    .transcircle8 {
        bottom: 11rem;
    }
    .transcircle9 {
        bottom: 9rem;
        right: 0;
        border: 15px solid #f7a29a;
    }
    .learningText{
        position: relative;
        top : -140px;
    }
    .learnheading {
        font-size: 40px;
        line-height: 1;
    }
    .learnheading2 {
        font-size: 60px;
        line-height: 1;
    }
    .learnpara {
        font-size: 25px;
    }
    .videobox1 {
        top: 15.5rem;
        left: 6.7%;
    }
    .videobox2 {
        bottom: 18.5rem;
        left: 24.5%;
    }
    .videobox3 {
        top: 15.5rem;
        left: 42.5%;
    }
    .videobox4 {
        bottom: 18.5rem;
        right: 23%;
    }
    .videobox5 {
        top: 15.5rem;
        right: 5.3%;
    }

    .curve{
        height: 50vh !important;
    }
    .titlebox4{
            left: 65px;
        
    }   
    .headingnumber2, .headingnumber4 {
        position: absolute;
        right: 25%;
    }
     .brandname {
        font-size: 70px !important;
    }
    .chooseheading{
        font-size: 42px;
    }
    .overlay{
        top : 100px !important
    }
    .learn{
        display: none;
    }
} */


  /* @media only screen and (min-width : 593px) and (max-width : 1200px){
    .navbar-nav{
        margin-left: 0px !important;
        flex-direction: inherit;
        left: -14px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbaar-nav {
        position: relative;
        left: 6px;
    }

    .btn-groups button{
        font-size : 16px;
        
    }

    .call-btn{
        position: relative;
        bottom: 110px;
        left : 62%;
    }

    .input-group{
        width:100% !important;
        position: relative;
        right : 16%;
    }

    .search-icon{
    left: 74%;
    }

    .credintial{
        width:fit-content
    }
    .credintial.border-right{
        left: 111px;
        height: 34px;
        top: 8px;
    }

    .toplayer {
        position: absolute;
        top: 88px;
        width: 209px;
    }
    #root {
        width: fit-content;
        width: 150vw;
        overflow: hidden;
    }
    .heading{
        left: 25px;
    top: 163px;
    font-size: 60px;
    }
    .subheading {
        font-size: 50px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        position: relative;
        top: 70px;
        left: 13px;
    }
    .subheadingtag {
        font-size: 55px;
    }
    .realbannerbox {
        font-size: 25px;
        position: relative;
        left: 16px;
        top: 70px;
    }
    .grass {
        position: absolute;
        bottom: 13px;

    }
    .boyimage {
        z-index: 8888;
        position: relative;
        top: 90px;
        left: 11px;
    }
    .starimage {
        position: absolute;
        z-index: 8888;
        bottom: 80px;
        left: 56%;
        top: 45%;
    }
    .banner {
    height: 150vh;
    background-size: cover;
    }
    .bg-layer {
        position: absolute;
        bottom: 30%;
        height: -5%;
    }
    .choosebg {
        position: absolute;
        top: -20% !important;
    }
    .educationyear {
        position: absolute;
        right: 100px;
        
    }
    .studentImage{
        padding-top: 15px;;
    }
    .yellowbox{
        padding-top: 40px;
    }
    .transcircle1 {
        top: 290px;
    }
    .transcircle2 {
        top: 240px;
    }
    .transcircle4 {
        top: 240px;
    }
    .transcircle3 {
        right: 20rem;
        top: 12%;
    }
    .transcircle5 {
        bottom: 9rem;
    }
    .transcircle6 {
        position: absolute;
        bottom: 11rem;
    }
    .transcircle7 {
        bottom: 9rem;
        right: 29%;
    }
    .transcircle8 {
        bottom: 11rem;
    }
    .transcircle9 {
        bottom: 9rem;
        right: 0;
        border: 15px solid #f7a29a;
    }
    .learningText{
        position: relative;
        top : -140px;
    }
    .learnheading {
        font-size: 40px;
        line-height: 1;
    }
    .learnheading2 {
        font-size: 60px;
        line-height: 1;
    }
    .learnpara {
        font-size: 25px;
    }
    .videobox1 {
        top: 16rem;
        left: 6.7%;
    }
    .videobox2 {
        bottom: 17.5rem;
        left: 24.5%;
    }
    .videobox3 {
        top: 16rem;
        left: 42.5%;
    }
    .videobox4 {
        bottom: 17.5rem;
        right: 23%;
    }
    .videobox5 {
        top: 16rem;
        right: 5.3%;
    }
    .titlebox4{
            left: 65px;
        
    }
    .headingnumber2, .headingnumber4 {
        position: absolute;
        right: 25%;
    }
}  */


/* 

@media only screen and (min-width : 768px){

}

@media only screen and (min-width : 992px){

}

@media only screen and (min-width:  1200px){

} */
